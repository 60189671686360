// Vendor
require('twitter-typeahead-rails/vendor/assets/javascripts/twitter/typeahead/typeahead.bundle');

import Bloodhound from 'typeahead.js/dist/bloodhound.js'

Blacklight.onLoad(function() {

    var elementary_json;
    var middle_json;
    var highschool_json;
    var k12_json;
    var otherk12_json;
    var publiclib_json;
    var highered_json;

    $.getJSON('/wayfinder_data.json', function(data) {
        // TODO: remove 'elem' and 'midd' after galileo_admin:site_type feature flag is done
        if( data['elem'].length ) {
            elementary_json = data['elem'];
        }
        else {
            elementary_json = data['elementary'];
        }
        if( data['midd'].length ) {
            middle_json = data['midd'];
        }
        else {
            middle_json = data['middle'];
        }
        highschool_json = data['highschool'];
        k12_json = data['k12'];
        otherk12_json = data['otherk12'];
        publiclib_json = data['publiclib'];
        highered_json = data['highered'];
    }).done( function(){

        var all_k12_json = k12_json.concat(elementary_json).concat(middle_json)
            .concat(highschool_json).concat(otherk12_json);

        function customTokenizer(datum) {
          var nameTokens = Bloodhound.tokenizers.nonword(datum.name);
          var codeTokens = Bloodhound.tokenizers.nonword(datum.code);
          var allTokens = nameTokens.concat(codeTokens);
          if(datum.keywords) {
              var keywordTokens = Bloodhound.tokenizers.nonword(datum.keywords);
              allTokens = allTokens.concat(keywordTokens);
          }

          return allTokens;
        }

        var elementaryEntries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: elementary_json
        });
        var middleEntries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: middle_json
        });
        var highschoolEntries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: highschool_json
        });
        var allk12Entries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: all_k12_json
        });
        var k12Entries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: k12_json
        });
        var otherk12Entries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: otherk12_json
        });
        var publiclibEntries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: publiclib_json
        });
        var higheredEntries = new Bloodhound({
            datumTokenizer: customTokenizer,
            queryTokenizer: Bloodhound.tokenizers.nonword,
            local: highered_json
        });

        var max_institution_results_all_tab = 30;
        var max_institution_results_k12_tab = 50;
        var max_institution_results_individual_tab = 200;

        $('.typeahead-all').typeahead({
                autoselect: true,
                hint: true,
                autofocus: true,
                minLength: 1,
                highlight: true,
                classNames: { highlight: 'unbold' }
            },
            {
                name: 'allk12',
                display: 'name',
                source: allk12Entries,
                limit: max_institution_results_all_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">K-12</h4>'
                }
            },
            {
                name: 'publiclib',
                display: 'name',
                source: publiclibEntries,
                limit: max_institution_results_all_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">Public Libraries</h4>'
                }
            },
            {
                name: 'highered',
                display: 'name',
                source: higheredEntries,
                limit: max_institution_results_all_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">Colleges & Universities</h4>'
                }
            }
        ).focus();

        $('.typeahead-k12').typeahead({
                autoselect: true,
                hint: true,
                minLength: 1,
                highlight: true,
                classNames: { highlight: 'unbold' }
            },
            {
                name: 'elementary',
                display: 'name',
                source: elementaryEntries,
                limit: max_institution_results_k12_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">Elementary Schools</h4>'
                }
            },
            {
                name: 'middle',
                display: 'name',
                source: middleEntries,
                limit: max_institution_results_k12_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">Middle Schools</h4>'
                }
            },
            {
                name: 'highschool',
                display: 'name',
                source: highschoolEntries,
                limit: max_institution_results_k12_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">High Schools</h4>'
                }
            },
            {
                name: 'otherk12',
                display: 'name',
                source: otherk12Entries,
                limit: max_institution_results_k12_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">Other Schools</h4>'
                }
            },
            {
                name: 'k12',
                display: 'name',
                source: k12Entries,
                limit: max_institution_results_k12_tab,
                templates: {
                    header: '<h4 class="wayfinder-inst-type">Districts, Private K12, RESAs</h4>'
                }
            }
        );

        $('.typeahead-publiclib').typeahead({
                autoselect: true,
                hint: true,
                minLength: 1,
                highlight: true,
                classNames: { highlight: 'unbold' }
            },
            {
                name: 'publiclib',
                display: 'name',
                source: publiclibEntries,
                limit: max_institution_results_individual_tab,
            }
        );

        $('.typeahead-highered').typeahead({
            autoselect: true,
            hint: true,
            minLength: 1,
            highlight: true,
            classNames: { highlight: 'unbold' }
        },
            {
                name: 'highered',
                display: 'name',
                source: higheredEntries,
                limit: max_institution_results_individual_tab,
            }
        );

        // submit wayfinder form if selection is explicitly made
        $('.typeahead').bind('typeahead:select', function(ev, suggestion) {
            $(this).closest('form').submit();
        });

    });
});

